exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-adv-search-js": () => import("./../../../src/pages/components/adv-search.js" /* webpackChunkName: "component---src-pages-components-adv-search-js" */),
  "component---src-pages-components-filter-js": () => import("./../../../src/pages/components/filter.js" /* webpackChunkName: "component---src-pages-components-filter-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-nav-js": () => import("./../../../src/pages/components/nav.js" /* webpackChunkName: "component---src-pages-components-nav-js" */),
  "component---src-pages-components-search-form-js": () => import("./../../../src/pages/components/search-form.js" /* webpackChunkName: "component---src-pages-components-search-form-js" */),
  "component---src-pages-components-tool-filter-js": () => import("./../../../src/pages/components/tool-filter.js" /* webpackChunkName: "component---src-pages-components-tool-filter-js" */),
  "component---src-pages-datasets-js": () => import("./../../../src/pages/datasets.js" /* webpackChunkName: "component---src-pages-datasets-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-dataset-js": () => import("./../../../src/templates/dataset.js" /* webpackChunkName: "component---src-templates-dataset-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */)
}

